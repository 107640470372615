// TODO: Expand this into multiple files as it grows.

import { type InjectionKey, type Ref, ref } from "vue"
import {
  type CompanyBenefitsApi,
  type MemberEnrollment,
  type ActiveEmployeeBenefit,
  type OpenEmployeeEnrollment,
  type PastEmployeeBenefit,
  type QuoteStatuses,
  type BenefitsOverviewEnrollments,
  type OverviewSummary,
  type Viewer,
  type MemberOfferingData,
  PayrollProvider,
} from "@/types"

export const ViewerSymbol = Symbol("Viewer") as InjectionKey<Ref<Viewer>>
export const viewerDefault = ref({
  enabled_feature_flags: [],
  member: {
    id: "",
    abilities: [],
    preferred_name: "",
    first_name: "",
    last_name: "",
    payroll_provider: PayrollProvider.JUSTWORKS_PEO,
    health_advocate_enabled: false,
    back_button_routing_text: "",
    back_button_routing_url: "",
  },
  company: {
    id: "",
    name: "",
    primary_location: {
      street_one: "",
      street_two: "",
      city: "",
      state: "",
      zip_code: "",
    },
  },
})

export const CompanyBenefitsFetchSymbol = Symbol(
  "CompanyBenefits"
) as InjectionKey<{
  getCompanyBenefits: () => Promise<void>
  isFetchingCompanyBenefits: Ref<boolean>
  companyBenefits: Ref<CompanyBenefitsApi>
}>

export const companyBenefitsFetchDefault = {
  getCompanyBenefits: () => Promise.resolve(),
  isFetchingCompanyBenefits: ref(false),
  companyBenefits: ref({
    enrollments: [],
    offerings: [],
    active_employees_enrolled: {},
    upcoming_employees_enrolled: {},
  }),
}

export const MemberEnrollmentsFetchSymbol = Symbol() as InjectionKey<{
  isFetchingMemberEnrollments: Ref<boolean>
  memberEnrollments: Ref<MemberEnrollment[]>
}>

export const memberEnrollmentsFetchDefault = {
  isFetchingMemberEnrollments: ref(false),
  memberEnrollments: ref([]),
}

export const MemberOfferingsFetchSymbol = Symbol() as InjectionKey<{
  isFetchingMemberOfferings: Ref<boolean>
  memberOfferings: Ref<MemberOfferingData[]>
}>

export const memberOfferingsFetchDefault = {
  isFetchingMemberOfferings: ref(false),
  memberOfferings: ref([]),
}

export const EmployeesActiveBenefitsFetchSymbol = Symbol() as InjectionKey<{
  fetchingActiveBenefits: Ref<boolean>
  activeBenefits: Ref<ActiveEmployeeBenefit[]>
}>

export const employeesActiveBenefitsFetchDefault = {
  fetchingActiveBenefits: ref(false),
  activeBenefits: ref([]),
}

export const EmployeesOpenEnrollmentsFetchSymbol = Symbol() as InjectionKey<{
  fetchingOpenEnrollments: Ref<boolean>
  openEnrollments: Ref<OpenEmployeeEnrollment[]>
}>

export const employeesOpenEnrollmentsFetchDefault = {
  fetchingOpenEnrollments: ref(false),
  openEnrollments: ref([]),
}

export const EmployeesPastBenefitsFetchSymbol = Symbol() as InjectionKey<{
  fetchingPastBenefits: Ref<boolean>
  pastBenefits: Ref<PastEmployeeBenefit[]>
}>

export const employeesPastBenefitsFetchDefault = {
  fetchingPastBenefits: ref(false),
  pastBenefits: ref([]),
}
export const CompanyQuotesFetchSymbol = Symbol() as InjectionKey<{
  isFetchingCompanyQuotes: Ref<boolean>
  companyQuotesData: Ref<QuoteStatuses>
}>

export const companyQuotesFetchDefault = {
  isFetchingCompanyQuotes: ref(false),
  companyQuotesData: ref([]),
}

export const BenefitsOverviewFetchSymbol = Symbol() as InjectionKey<{
  benefitsOverview: Ref<BenefitsOverviewEnrollments>
  isFetchingBenefitsOverview: Ref<boolean>
}>

export const benefitOverviewFetchDefault = {
  isFetchingBenefitsOverview: ref(false),
  benefitsOverview: ref({
    companyName: "",
    message: "",
    contributions: false,
    enrollments: [],
  }),
}

export const benefitOverviewSummarySymbol = Symbol() as InjectionKey<{
  benefitOverviewSummaryData: Ref<OverviewSummary>
  isFetchingBenefitOverviewSummary: Ref<boolean>
}>

export const benefitOverviewSummaryFetchDefault = {
  isFetchingBenefitOverviewSummary: ref(false),
  benefitOverviewSummaryData: ref({ classes: [], records: [] }),
}
